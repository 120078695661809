@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

:root {
  --primary-color: #f7c41e;
  --secondary-color: #6c757d;
  --background-color: #f5f5f5;
  --text-color: #333;
  --font-family: 'Arial', sans-serif;
  --button-background: #f7c41e;
  --button-hover-background: #f7c41e;
  --delete-background: rgb(136, 4, 4);
  --delete-hover-background: rgb(187, 16, 16);
  --api-key-background: #f1f1f1;
  --api-button-background: #eee;
  --api-button-hover-background: #ccc;
  --section-header-background: #f1f1f1;
  --section-content-background: #f9f9f9;
  --odd-section-background: #f5f5f5;
  --even-section-background: #f1f1f1;
  --api-summary-background: #f5f5f5;
  --api-subsection-background: #dcdcdc;
  --api-subsection-hover-background: #CCC;
  --completion-bar-background: #ddd;
  --completion-level-background: #6db65b;
}
.MuiFormControl-root label{
  background:#FFF; padding:2px;
}
.MuiSvgIcon-root{
  vertical-align:bottom;
}
.chain-selection-list.null{
  cursor:none;

}
.null textarea{
  pointer-events: none; /* Prevents mouse interaction with the textarea */
  background-color: #e0e0e0; /* A gray background to indicate it's disabled */
  color: #a0a0d0; /* Dimmed text color */
  /* Add any other styles to indicate it's disabled */
}
.null textarea::placeholder{
  color: #ee0b0b; /* Dimmed text color */
}

body {
  font-family: 'Montserrat', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue' !important;
  font-Family: ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace;
}
table{ min-width:600px; }
body {
  margin: 0;
  font-family: -apple-system, 
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.load-bg{
  position:fixed;
  left:0; top:0;
  width:100vw;
  z-index:100;
  height: 100vh;

  background:rgba(255,255,255,0.7);
}

.loading-container {
  display: flex;
  margin-top:30vh;
  align-items:center;
  justify-content: center;
}
.loading-container img{
  width:80px;
}
.loading-container.complete *{
  display:none;
}
.loading-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--primary-color);
  opacity:0.5;
  animation: loading 1s infinite;
}

.loading-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--primary-color);
  opacity:0.5;
  animation: loading 1s infinite;
}

.loading-text {
margin:20px; 
}

.loading-circle:nth-child(2) {
  animation-delay: 0.2s;
  
}

.loading-circle:nth-child(3) {
  animation-delay: 0.4s;
}

.loading-circle:nth-child(4) {
  animation-delay: 0.6s;
}

@keyframes loading {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
    opacity:0.9;
  }
  100% {
    transform: scale(1);
    opacity:0.5;
  }
}
.button:hover{ opacity:0.7; }


.App-header {
  color: white;
}
.contact-form button {
  background-color: #2196f3;
  color: #fff;
}
button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.App-link {
  color: #61dafb;
}
button{
background-color:  #0056b3;
color: #fff;
}
button:hover{
  background-color:  #2c8df5;
}
.delete {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: rgb(136, 4, 4);
  border: none;
  cursor: pointer;
}
.delete:hover {
  background-color: rgb(187, 16, 16);
}
.api-key {
  font-family: monospace;
  font-size: 14px;
  padding: 5px;
  background-color: #f1f1f1;
  border-radius: 3px;
}

.api-secret {
  margin-left: 10px;
  font-size: 14px;
  color: #888;
}

.api-button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #eee;
  border: none;
  cursor: pointer;
}


.api-button:hover {
  background-color: #ccc;
}


.api-list-container {
  background-color: #f8f8f8;
}

.section-header {

  background-color: #f1f1f1;
 
}



.section-header h3 {

  color: #333;
}

.section-content {
  background-color: #f9f9f9;

  border: 1px solid #ddd;
}

.api-section {
  margin-bottom: 10px;
}

.odd-section {
  background-color: #f5f5f5;
}

.even-section {
  background-color: #f1f1f1;
}

.api-summary {

  background-color: #f5f5f5;

}

.MuiCard-root, .MuiBox-root{
  animation: slide-in 0.3s ease;
}

.MuiTab-root{
  animation: fade-in 0.3s ease;
}

.api-summary h4 {

  color: #555;
}
.api-subsection .api-summary{
  background:#dcdcdc;
}

.api-subsection .api-summary:hover {
  background-color: #CCC;
}




.api-request h5,
.api-response h5 {
  color: #333;
}



.api-request ul li,
.api-response ul li {
  margin-bottom: 5px;
  font-size: 14px;

}
th,
td {
  border: 1px solid #ddd;
}

tr:hover td{ background:#FFF; }
thead th {
  background-color: #f2f2f2;
}


.contract-list-container .completion-bar {

  background-color: #ddd;

}

.contract-list-container .completion-level {

  background-color: #6db65b;

}
.transaction {

  color: #000;

}

