

body {
  font-family: var(--font-family);
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}


.App {
  text-align: center;
  margin: 0px auto;
  height:100vh;
  max-width:100vw;
  overflow-x:hidden;
}
.right-panel{
  max-width:calc(100vw - 20px);
}
.rtab{
right:0;
}
.rtab.visible{
right:400px;
}
.Block{padding-top:20%; position:absolute; top:0; left:0; background:rgba(255,255,255,.9); width:100%; height:100%; z-index:25;   }
.milestonecon .milestones {
  display: inline-block;
  width: 200px;
}

.milestonecon .wizard {
  margin: 10px;
  display: inline-block;
  width: calc(100% - 250px);
  vertical-align: top;
}
.fullview{ width:calc(100% - 120px); }
.shownav{
  position:relative;
  padding-left: 100px;
}
.fullview.hidenav{ width:100%; }
.hidenav{
  padding:0;
}
.reduced.shownav{ width:calc(100% - 450px); }
.hidenav{
  padding:0;
}
.reduced{
  position:relative;
  width:calc(100% - 400px);
}
.App-header {
  background-color: var(--primary-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);

}


.contact-form input, select {
  flex: 1;
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

 input,
 select,
 textarea {
  width: 100%; padding:15px;
  font-size:14px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
}

.contact-form button {
  padding: 8px 12px;

  border: none;
  border-radius: 2px;
  cursor: pointer;
}


button:disabled {

  cursor: not-allowed;
}
button{
padding: 10px;
border: none;
border-radius: 2px;

font-size: 16px;
cursor: pointer;
}

.delete {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Styles for desktop and larger screens */
@media screen and (min-width: 1200px) {
  /* Add your CSS rules here */
}

/* Styles for tablet-sized screens */
@media screen and (min-width: 440px) and (max-width: 1199px) {
  /* Add your CSS rules here */
  .templatedetails{
    display:none;
  }
 
}

/* Styles for mobile and smaller screens */
@media screen and (max-width: 440px) {
  /* Add your CSS rules here */
  .rtab {
    margin-top: 30vh;
  }
  .rtab.visible{
    left:0;
    right:auto;
    }
    .templatedetails{
      display:none;
    }
  .config >div{
    max-width:100%;
    min-width:0; margin:0;
    display:block;
  }

  
}
